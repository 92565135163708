html, body
{
    color: #8a8686 !important;
    background: #fff;
    font-family: Verdana, sans-serif;
    letter-spacing: 1px;
    font-size: 100%;
    margin: 0;
    padding: 0;
}

.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.large {
  font-size: 2em;
}

h3
{
    margin: 3em 0 1em 0;
    text-align: center;
    font-size: 1.2em;
    padding-bottom: 0.3em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

h4
{
}

#nav h4
{
  margin-top: 1em;
  margin-bottom: 1em;
}

a, a:visited
{
    color: #0092b6;
    text-decoration: none;
}

.landing_page 
{
    text-align: center;
}

#header
{
    background: #000;
    height:  80px;
    padding-top: 32px;
    padding-bottom: 32px;
    width: auto;
    text-align: center;
    clear: both;
}

#footer
{
    background: #000;
    height:  32px;
    margin-top: 32px;
    width: auto;
    text-align: center;
    clear: both;
}

#header img
{
    height: 100%;
    max-height: 100%;
}

#content
{
    margin-top: 2em;
    margin: 0 auto;
    width: 50%;
    min-width: 600px;
}

ul#nav 
{
    margin-bottom: 2em;
}

ul#nav, ul#nav li
{
    padding-left: 0;
    list-style: none;
    list-style-type: none;
}

ul#nav li
{
    font-size: 1.2em;
    display: inline;
    padding-right: 1.5em;
}

ul#nav li ul.lang_list
{
    padding-left: 0;
    margin: 0;
}

ul#nav li ul.lang_list li
{
    display: block;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0.75em;
}

.entry-content h1,
.title-post {
  text-align: center;
}

.entry-content {
  margin: 0 auto;
  max-width: 800px;
}

.return-button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 32px;
  line-height: 1;
  border-radius: 12px;
  border: 3px solid #0092b6;
  min-width: 50px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 12px;
  color: #0092b6;
}

.return-button:visited {
  color: #0092b6;
}

li h3,
li h3.no-margin {
  margin-bottom: 1em;
}

.toc li {
  margin-bottom: 0.5em;
}

.no-bullet li,
.no-bullet {
  list-style-type: none;
}

#hh-tos h2 {
  margin: 1.5em 0 0.5em 0;

}

#hh-tos h3 {
  text-align: left;
  /* padding-left: 3em; */
  margin: 1em 0;
}

#hh-tos table {
  border: 0;
  border-collapse: collapse;
}

#hh-tos table td {
  padding: 1em;
  border: 1px solid black;
}